import request from './request'

//权限列表
export const route_index_request = p => {
    return request({
        method:'GET',
        url:'route/index',
        params: p
    })
}

//添加权限
export const route_add_request = d => {
    return request({
        method:'POST',
        url:'route/add',
        data: d
    })
}

//删除权限
export const route_del_request = id => {
    return request({
        method:'DELETE',
        url:'route/del',
        data: {
            id: id
        }
    })
}

//修改权限
export const route_edit_request = data => {
    return request({
        method:'PUT',
        url:'route/edit',
        data
    })
}

//获取权限详情
export const route_details_request = id => {
    return request({
        method:'GET',
        url:'route/details',
        params: {
            id
        }
    })
}