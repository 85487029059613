<template>
    <div v-loading.fullscreen.lock="loading">
        <route-search-bar-component
            add_auth="新增权限路由"
            :AllModel="fAllModel"
            @search="get_route_index"
            @addTeam="addTeam"
        ></route-search-bar-component>
        <common-table-component
            details_auth="权限路由详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_route"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_route_index"
        ></common-page-component>
        <route-edit-component
            edit_auth="修改权限路由"
            del_auth="删除权限路由"
            :id="fid"
            :show_type="edit_type"
            :AllModel="fAllModel"
            :dialogFormVisible="fdialogFormVisible"
            :route_details_data="froute_details_data"
            :route_model_team="route_model_team"
            @exitRouteDialog="dialogExit"
            @search="get_route_index"
            @show_edit="show_edit"
            @details_row="details_route"
        ></route-edit-component>
    </div>
</template>

<script>
import { route_index_request,route_details_request } from '@/network/route.js'
import { route_model_team_request } from '@/network/RouteModel.js'
import { route_model_list_request } from '@/network/list.js'

import RouteSearchBarComponent from '@/components/route/RouteSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import RouteEditComponent from '@/components/route/RouteEditComponent'

export default {
    name: 'RouteView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                name: '',
                model_id: '',
                team_id: '',
                open_look_auth: '',
                menu:'',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'iconImg',
                    label: '图标',
                    width: '70px',
                    icon: true,
                },
                {
                    prop: 'name',
                    label: '权限名称',
                    minWidth: '180px'
                },
                {
                    prop: 'open_look_auth',
                    label: '查看限制',
                    width: '100px',
                    tag: (d) => {
                        if( d ) {
                            return {
                                type: 'warning',
                                filter_data: '有限制'
                            }
                        }

                        return {
                                type: 'info',
                                filter_data: '无限制'
                            }
                    }
                },
                {
                    prop: 'menu',
                    label: '菜单',
                    width: '70px',
                    tag: (d) => {
                        if( d ) {
                            return {
                                type: 'success',
                                filter_data: '是'
                            }
                        }

                        return {
                                type: 'danger',
                                filter_data: '否'
                            }
                    }
                },
                {
                    prop: 'router',
                    label: '前端路由',
                    minWidth: '150px'
                },
                {
                    prop: 'url',
                    label: '后端路由',
                    minWidth: '350px'
                },
                {
                    prop: 'team_name',
                    label: '所属分组',
                    minWidth: '150px'
                },
                {
                    prop: 'model_name',
                    label: '所属分类',
                    minWidth: '150px'
                },
            ],
            froute_details_data: {},
            fAllModel: [],
            route_model_team: []
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_route_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.model_id = param.model_id ?? this.cond.model_id
            this.cond.team_id = param.team_id ?? this.cond.team_id
            this.cond.open_look_auth = param.open_look_auth ?? this.cond.open_look_auth
            this.cond.menu = param.menu ?? this.cond.menu
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            route_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data.map((v) => {
                            v.iconImg = v.icon
                            return v
                        })
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_route(id) {
            this.fid = id
            this.fdialogFormVisible = true
            route_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.froute_details_data = s.result
                        this.froute_details_data.team_id = [
                            s.result.model_id,
                            s.result.team_id,
                        ]
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_all_model() {
            route_model_list_request({})
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllModel = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_route_model_team() {
            route_model_team_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.route_model_team = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            //解决弹窗有些数据不清空的BUG
            this.froute_details_data = {}
            this.fid = 0
            this.edit_type = 0
        },
    },
    filters: {},
    props: {},
    created() {
        this.get_route_index()
        this.get_all_model()
        this.get_route_model_team()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        RouteSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        RouteEditComponent,
    },
    watch: {},
}
</script>

<style lang='less'></style>