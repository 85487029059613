<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="权限名称" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="权限名称搜索"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="所属分类" prop="model_id">
                <el-select
                    v-model="form.model_id"
                    placeholder="选择分类"
                    class="modelc"
                    @change="(model_id) => get_team(model_id)"
                    clearable
                >
                    <el-option
                        v-for="model in AllModel"
                        :key="model.name"
                        :label="model.name"
                        :value="model.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属分组" prop="team_id">
                <el-select
                    v-model="form.team_id"
                    placeholder="选择分组"
                    class="teamc"
                    clearable
                >
                    <el-option
                        v-for="team in this.teamInModel"
                        :key="team.name"
                        :label="team.name"
                        :value="team.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="查看限制" prop="open_look_auth">
                <el-select
                    v-model="form.open_look_auth"
                    placeholder="请选择"
                    class="open_look_authc"
                    clearable
                >
                    <el-option label="有限制" :value=1></el-option>
                    <el-option label="无限制" :value=0></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="菜单显示" prop="menu">
                <el-select
                    v-model="form.menu"
                    placeholder="请选择"
                    class="menuc"
                    clearable
                >
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加权限</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
import { route_team_list_request } from '@/network/list.js'

export default {
    name: 'RouteSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                name: '',
                model_id: '',
                team_id: '',
                open_look_auth: '',
                menu:'',
                page: 1,
            },
            teamInModel: []
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.teamInModel = []
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
        get_team(model_id) {
            this.form.team_id = ''
            this.teamInModel = []
            if (model_id) {
                route_team_list_request({ model_id })
                    .then((s) => {
                        this.loading = false
                        if (s.status === 0) {
                            this.teamInModel = s.result
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        this.loading = false
                        this.$message.error(err)
                    })
            }
        }
    },
    filters: {},
    props: {
        AllModel: {
            type: Array,
            default() {
                return []
            },
        },
        add_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}

.menuc {
    width: 100px;
}
</style>